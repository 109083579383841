<template>
  <plc-page :slide="false" :pic-url="banner">
    <div class="fixed-width page-content page-solution">
      <div class="submenu-wrapper">
        <div @click="changeMenu(index)" v-for="(item, index) in menu" :key="index" class="item item-4" :class="{active: item.active}">
          <div class="submenu-name" v-html="item.name"></div>
          <img v-if="item.active" class="submenu-arrow" src="../assets/images/arrow-down-white.png">
          <img v-else class="submenu-arrow" src="../assets/images/arrow-down-black.png">
        </div>
      </div>
      <div class="content" v-if="currentMenu === 0">
        <h1 class="plc-h1">系统概述</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">现代养猪业采用集中养殖方式，养殖密度大，管理复杂程度高。为了解决这些问题，把先进的人工智能技术引进到生产管理的过程中，从而进一步提高了生产管理水平。</p>
          <p class="plc-p">普立兹人工智能养猪系统由摄影仪、交换机、硬盘录像机、智慧引擎运算服务器和手机APP软件组成。系统通过安装在猪舍栏位上方的摄影仪实时采集猪只影像，利用光纤传输到硬盘录像机并上传至运算服务器，服务器对接收到的影像进行智能分析和处理，从而让用户可以远程在手机上随时随地查看猪只数量及日增重等数据，改变了传统上依赖个人经验的不可靠，提高效率，且避免对猪只产生任何应激，帮助养猪场提高养殖成绩，真正实现智慧养殖新模式。</p>
        </div>
        <h1 class="plc-h1">系统方案组成</h1>
        <div class="plc-p-wrapper">
          <div class="plc-p-with-pic">
            <div class="pic-wrapper pic-mr">
              <img class="pic" alt="系统方案" title="系统方案" src="../assets/images/solution/1-1.jpg">
            </div>
            <div class="pic-desc">
              <p class="plc-p">系统由摄影仪、交换机、硬盘录像机、智慧引擎运算服务器和手机APP软件组成。系统通过安装在猪舍栏位上方的摄影仪实时采集猪只影像，利用光纤传输到硬盘录像机并上传至运算服务器，服务器对接收到的影像进行智能分析和处理，从而让用户可以远程在手机上随时随地查看猪只数量及日增重等数据。</p>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">系统功能</h1>
        <div class="plc-p-wrapper">
          <div class="plc-h2-wrapper">
            <h2 class="plc-h2">1 猪只盘点</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">通过摄影仪将猪舍内每个栏位的猪只影像实时上传到后台智慧引擎运算服务器，然后通过人工智能算法软件自动算出猪只数量，通过手机APP/电脑Web页/可视化大屏可以实时查看。</p>
            </div>
            <h2 class="plc-h2">2 猪只均重</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">通过摄影仪将猪舍内每个栏位的猪只影像实时上传到后台智慧引擎运算服务器，然后通过人工智能算法软件估算出猪群的平均体长体宽，从而自动计算出每栏/每栋舍的平均体重，通过手机APP/电脑Web页/可视化大屏可以实时查看。</p>
            </div>
            <h2 class="plc-h2">3 猪只打堆报警</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">如果人工智能算法识别到猪只打堆现象会立刻发出警告，饲养员得到信息后可以进行人为干预，比如将栋舍温度调高，从而避免猪只应激反应甚至踩踏死亡事件。</p>
            </div>
            <h2 class="plc-h2">4 视频监控</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">栏位上方的摄影仪，可以实时监控猪只的动态，用户可远程查看实时视频和历史视频。</p>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">功能特点</h1>
        <div class="plc-p-wrapper">
          <div class="plc-h2-wrapper">
            <h2 class="plc-h2">1 避免猪只应激，采集效率高</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">传统的方式是人工巡检得出猪只数量，无法做到及时准确，效率低下；称重更是需要将猪赶到地磅上，容易使猪只产生应激反应，影响猪只情绪、采食，从而影响猪只成长。而通过非接触式视频采集的方式，结合人工智能软件算法，就可以得出相应的数据，避免猪只产生应激，不知不觉的完成了数据采集。</p>
            </div>
            <h2 class="plc-h2">2 数据实时性，数据真实性</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">传统的数据采集需要通过一线生产人员获得，费时费力效率低下，并且人为因素影响非常大，导致数据的准确性和真实性得不到保证，特别是大集团公司，对数据的准确性和真实性非常关注，通过人工智能的方式，将猪只影像数据传到后台，智慧引擎运算服务器可以实时给出数据，避免了人为因素的影响，保证了数据的实时性和真实性。</p>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">系统的优势</h1>
        <div class="plc-p-wrapper">
          <div class="plc-h2-wrapper">
            <h2 class="plc-h2">1 准确度持续提升</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">普立兹人工智能点数估重系统是2018年发布的，目前有大量的猪场应用案例。在确保摄像头无任何脏污及遮挡，人眼远程通过视频画面可以随时分辨清楚猪只个体的情况下，经猪场应用案例发现，满足特定条件下盘点准确度可以做到98.5%以上，估重的精度在92%以上（若客户配合提供现场数据供AI系统做深度学习，精度还可以再提高）。</p>
            </div>
            <h2 class="plc-h2">2 覆盖场景丰富</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">普立兹人工智能解决方案覆盖到猪场很多场景，适用的场景包括：保育猪、育肥猪、后备母猪、出猪台等。</p>
            </div>
          </div>
          <div class="pic-1-4-wrapper">
            <img class="width-300 height-160 margin-r-25 margin-b-25" alt="覆盖场景图-保育舍" title="覆盖场景图-保育舍" src="../assets/images/solution/1-2.jpg">
            <img class="width-300 height-160 margin-r-25 margin-b-25" alt="覆盖场景图-后备母猪" title="覆盖场景图-后备母猪" src="../assets/images/solution/1-3.jpg">
            <img class="width-300 height-160 margin-b-25" alt="覆盖场景图-育肥舍" title="覆盖场景图-育肥舍" src="../assets/images/solution/1-4.jpg">
            <img class="width-300 height-250 margin-r-25" alt="覆盖场景图-出猪台" title="覆盖场景图-出猪台" src="../assets/images/solution/1-5.jpg">
            <img class="width-625 height-250" alt="覆盖场景图-死猪摆放区" title="覆盖场景图-死猪摆放区" src="../assets/images/solution/1-6.jpg">
          </div>
        </div>
        <h1 class="plc-h1">价值</h1>
        <div class="plc-p-wrapper">
          <div class="plc-h2-wrapper">
            <h2 class="plc-h2">1 点数价值</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">准确及时的进行资产确权，掌握猪只存栏。</p>
            </div>
            <h2 class="plc-h2">2 估重价值</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">A、了解每天日增重，提前发现健康问题，对生产成绩进行过程监管。</p>
              <p class="plc-p2">B、增重曲线与饲喂产生的预想值做比对，及时调整营养，指导精准饲喂。</p>
              <p class="plc-p2">C、计算料肉比，对比生产成绩。</p>
            </div>
            <h2 class="plc-h2">3 打堆监测价值</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">及时发现猪只环境较冷，及早做干预，避免冷应激。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content" v-else-if="currentMenu === 1">
        <h1 class="plc-h1">系统概述</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">现代畜禽养殖业采用集中养殖方式，养殖密度大，管理复杂程度高，为了解决这些问题，把先进的AI人工智能算法技术引进到生产管理的过程中,使养殖达到了自动化、标准化和智能化，从而进一步提高了生产管理水平。</p>
          <p class="plc-p">该方案主要采用生物体测温传感器实时监测母猪体温、通过智能算法对母猪体温的变化进行分析和预警，让养殖管理人员通过移动终端及时了解母猪的健康状况，让管理人员可以提早发现生病猪只，改变了传统养殖依靠经验判断和发现猪只健康状况不够及时等问题。</p>
          <p class="plc-p">系统运用先进的AI人工智能技术提升用户的养殖管理水平，节省人员，提高效率，实现更加精细化的智慧养殖。设备间使用无线传输数据，实施简单快捷，即装即用。</p>
        </div>
        <h1 class="plc-h1">作用功能</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">针对产房母猪及定位栏母猪：</p>
          <p class="plc-p">1、体温实时监测和报警。</p>
          <p class="plc-p">2、活动力计算。</p>
        </div>
        <h1 class="plc-h1">价值</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">1、及时发现母猪健康异常，及早干预做治疗，避免影响生产成绩。</p>
          <p class="plc-p">2、实时监测母猪躁动情况，辅助判断母猪健康问题。</p>
        </div>
        <h1 class="plc-h1">系统拓扑图</h1>
        <div class="plc-p-wrapper">
          <div class="plc-p-with-pic">
            <div class="pic-mr">
              <div class="pic-wrapper">
                <img class="pic2-1" alt="使用有线网络的拓扑图" title="使用有线网络的拓扑图" src="../assets/images/solution/2-1.jpg">
              </div>
              <div class="pic-label">使用有线网络的拓扑图</div>
            </div>
            <div class="pic-mr">
              <div class="pic-wrapper">
                <img class="pic2-1" alt="使用4G网络的拓扑图" title="使用4G网络的拓扑图" src="../assets/images/solution/2-2.jpg">
              </div>
              <div class="pic-label">使用4G网络的拓扑图</div>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">方案产品配置</h1>
        <div class="plc-p-wrapper">
          <div class="plc-p-with-pic" style="align-items: flex-start">
            <div class="pic-mr plc-2-desc">
              <ul class="desc-item-wrapper">
                <li class="desc-item title">母猪体温传感器</li>
                <li class="desc-item" v-for="item in AI_PRODUCT_1" :key="item.key">
                  <div class="desc-key">{{item.key}}</div>
                  <div class="desc-value">{{item.value}}</div>
                </li>
              </ul>
              <ul class="desc-item-wrapper">
                <li class="desc-item title">AI服务器<span style="color: #444444;font-size: 14px">（可放置在本地、也可以布置在云端）</span></li>
                <li class="desc-item" v-for="item in AI_PRODUCT_2" :key="item.key">
                  <div class="desc-key">{{item.key}}</div>
                  <div class="desc-value" v-html="item.value"></div>
                </li>
              </ul>
            </div>
            <div class="pic-mr plc-2-desc">
              <ul class="desc-item-wrapper">
                <li class="desc-item title">猪舍WIFI热点</li>
                <li class="desc-item" v-for="item in AI_PRODUCT_3" :key="item.key">
                  <div class="desc-key">{{item.key}}</div>
                  <div class="desc-value" v-html="item.value"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">现场案例</h1>
        <div class="plc-p-wrapper">
          <div class="plc-p-with-pic">
          <img class="pic2-2 pic-mr" alt="现场案例1" title="现场案例1" src="../assets/images/solution/2-3.jpg">
          <img class="pic2-2 pic-mr" alt="现场案例2" title="现场案例2" src="../assets/images/solution/2-4.jpg">
          </div>
        </div>
      </div>
      <div class="content" v-else-if="currentMenu === 2">
        <h1 class="plc-h1">系统概述</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">现代畜禽养殖业采用集中养殖方式，养殖密度大，管理复杂程度高。为了解决这些问题，把先进的物联网技术引进到生产管理的过程中。使养殖达到了自动化、标准化和智能化，从而进一步提高了生产管理水平。</p>
          <p class="plc-p">该方案主要采用安装与畜禽类生长密切相关的空气温湿度、二氧化碳、氨气、风速、光照强度、气压等传感器，通过智能控制器把数据实时的传输到物联网平台上，从而可以远程通过智能手机或电脑进行数据查看和智能分析，并可通过智能控制器实现自动调控的功能。</p>
          <p class="plc-p">系统运用先进的物联网技术提升用户的养殖管理水平，节省人员，提高效率，实现更加精细化的智慧养殖。设备间使用无线传输数据，除了不易遭受雷击、强电等干扰，还免去现场布线的成本，施工简便快捷，即装即用；手机APP也无需下载安装，无需注册账号，只需扫描二维码关注微信公众号即可，即扫即用。</p>
        </div>
        <h1 class="plc-h1">系统方案组成</h1>
        <div class="plc-p-wrapper">
          <div class="plc-p-with-pic">
            <div class="pic-wrapper pic-mr">
              <img class="pic" alt="系统方案" title="系统方案" src="../assets/images/solution/3-1.jpg">
            </div>
            <div class="pic-desc">
              <p class="plc-p">系统由智能控制器、各种传感器和软件组成；主要实现：对畜禽舍环境进行实时监测，对环境异常和断电情况立即报警，对异常环境进行自动调控，对历史环境进行查询分析。该系统将高科技无线设备与手机软件有机结合，让用户通过随身手机随时随地、更加容易的管理养殖场，真正把养殖场装进口袋中。</p>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">系统功能</h1>
        <img class="img-func" src="../assets/images/solution/3-2.jpg">
        <div class="plc-p-wrapper">
          <div class="plc-h2-wrapper">
            <h2 class="plc-h2">1 环境监测</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">环境指标实时监测：室外温度、室内温度、平均温度、点温差、湿度、二氧化碳、氨气、风速、光照、负压等。</p>
              <p class="plc-p2">监测频率：全天24小时不间断监测。</p>
            </div>
            <h2 class="plc-h2">2 异常报警</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">以下异常时立即报警：环境超标、舍内断电、设备离线、传感器异常。</p>
              <p class="plc-p2">三种告警方式：微信消息推送、给用户打电话、本地声光报警。</p>
              <p class="plc-p2">环境报警设置：可按探头、点温差、平均、高温、低温等多种组合。</p>
            </div>
            <h2 class="plc-h2">3 自动调控</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">自动调控风机、水帘等设备。</p>
              <p class="plc-p2">电箱带缺相保护，防雷击装置，防浪涌装置，过载保护，断电报警，对调控设备进行全方位防护。</p>
            </div>
            <h2 class="plc-h2">4 报表分析</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">统计：环境合格率、报警次数、报警流水、时长统计、每天告警时间分布。</p>
              <p class="plc-p2">支持按日/月进行柱状图展示环境合格率、告警总次数、告警时长、各种告警的次数。</p>
              <p class="plc-p2">温湿度等环境指标曲线走势图，与标准曲线对比图。</p>
              <p class="plc-p2">温度和风机等级对应图。</p>
            </div>
            <h2 class="plc-h2">5 其他</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">智能控制器内置电池，即使设备断电，依然可以继续工作24小时以上。</p>
              <p class="plc-p2">通过手机远程配置智能控制器。</p>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">功能特点</h1>
        <div class="plc-p-wrapper">
          <div class="plc-h2-wrapper">
            <h2 class="plc-h2">1 自动调控</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">可自动调控风机（恒速/变速）、水帘、侧窗、加热器等；可通过手机远程设置控制器参数，无需进入栋舍。</p>
            </div>
            <h2 class="plc-h2">2 实时监测</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">通过随身手机或电脑，随时了解猪舍内实时的环境指标，保证良好的生长环境。</p>
            </div>
            <h2 class="plc-h2">3 异常报警</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">一旦发现环境异常（温度、有害气体等）及断电立即通过手机同时提示到多个相关人员。</p>
            </div>
            <h2 class="plc-h2">4 报表分析</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">通过收集到的历史环境数据与猪只的生产指标做关联分析，提供报表给用户做决策依据。</p>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">价值</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">1、养殖操心少，用设备代替人工巡检、人工控制，使养殖员更轻松的养殖，生活更加惬意。</p>
          <p class="plc-p">2、随时随地，远程监控养殖，打开手机或电脑，随时查看舍内实时的环境指标。</p>
          <p class="plc-p">3、可靠报警，守护猪只安全，断电、环境异常时都可以立即远程报警，持续保证牲畜的良好居住环境，降低疾病风险。</p>
          <p class="plc-p">4、批量监督，集中管理，所有的环境大数据记录在服务器中，通过各种报表分析对所有的养殖场进行对比、发现问题、快速找到问题，为后续不断优化养殖提供决策依据。</p>
        </div>
      </div>
      <div class="content" v-else-if="currentMenu === 3">
        <h1 class="plc-h1">系统概述</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">现代畜禽养殖业采用集中养殖方式，养殖密度大，时常出现各种异常（断电、舍内环境异常等）。为了解决这些问题，把先进的物联网技术引进到生产管理的过程中。使养殖达到了自动化、标准化和智能化，从而进一步提高了生产管理水平。</p>
          <p class="plc-p">该方案主要采用安装与畜禽类生长密切相关的空气温湿度、二氧化碳、氨气、风速、光照强度、气压等传感器，通过智能采集器把数据实时的传输到物联网平台上，从而可以远程通过智能手机或电脑进行数据查看和智能分析，遇到舍内断电和环境异常时会立即给用户报警。</p>
          <p class="plc-p">系统运用先进的物联网技术提升用户的养殖管理水平，节省人员，提高效率，实现更加精细化的智慧养殖。设备间使用无线传输数据，除了不易遭受雷击、强电等干扰，还免去现场布线的成本，施工简便快捷，即装即用；手机APP也无需下载安装，无需注册账号，只需扫描二维码关注微信公众号即可，即扫即用。</p>
        </div>
        <h1 class="plc-h1">系统拓扑图</h1>
        <div class="plc-p-wrapper">
          <div class="plc-p-with-pic">
            <div class="pic-wrapper pic-mr">
              <img class="pic" alt="系统方案拓扑图" title="系统方案拓扑图" src="../assets/images/solution/4-1.jpg">
            </div>
            <div class="pic-desc">
              <p class="plc-p">系统由智能控制器、各种传感器和软件组成；主要实现：对畜禽舍环境进行实时监测，对环境异常和断电情况立即报警，对历史环境进行查询分析。</p>
              <p class="plc-p">该系统将高科技无线设备与手机软件有机结合，让用户通过随身手机随时随地、更加容易的管理养殖场，真正把养殖场装进口袋中。</p>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">系统功能</h1>
        <img class="img-func" src="../assets/images/solution/4-2.jpg">
        <div class="plc-p-wrapper">
          <div class="plc-h2-wrapper">
            <h2 class="plc-h2">1 环境监测</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">环境指标实时监测：室外温度、室内温度、平均温度、点温差、湿度、二氧化碳、氨气、风速、光照、负压等。</p>
              <p class="plc-p2">动物体感温度。</p>
              <p class="plc-p2">监测频率：全天24小时不间断监测。</p>
            </div>
            <h2 class="plc-h2">2 异常报警</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">以下异常时立即报警：环境超标、舍内断电、设备离线、传感器异常。</p>
              <p class="plc-p2">三种告警方式：微信消息推送、给用户打电话、本地声光报警。</p>
              <p class="plc-p2">环境报警设置：可按探头、点温差、平均、高温、低温等多种组合。</p>
            </div>
            <h2 class="plc-h2">3 报表分析</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">统计：环境合格率、报警次数、报警流水、时长统计、每天告警时间分布。</p>
              <p class="plc-p2">支持按日/月进行柱状图展示环境合格率、告警总次数、告警时长、各种告警的次数。</p>
              <p class="plc-p2">温湿度等环境指标曲线走势图，与标准曲线对比图。</p>
              <p class="plc-p2">温度和风机等级对应图。</p>
            </div>
            <h2 class="plc-h2">4 其他</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">智能控制器内置电池，即使设备断电，依然可以继续工作24小时以上。</p>
              <p class="plc-p2">通过手机远程配置智能控制器。</p>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">功能特点</h1>
        <div class="plc-p-wrapper">
          <div class="plc-h2-wrapper">
            <h2 class="plc-h2">1 实时监测</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">通过随身手机或电脑，随时了解猪舍内实时的环境指标，保证良好的生长环境。</p>
            </div>
            <h2 class="plc-h2">2 异常报警</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">一旦发现环境异常（温度、有害气体等）及断电立即通过手机同时提示到多个相关人员。</p>
            </div>
            <h2 class="plc-h2">3 报表分析</h2>
            <div class="plc-p2-wrapper">
              <p class="plc-p2">通过收集到的历史环境数据与猪只的生产指标做关联分析，提供报表给用户做决策依据。</p>
            </div>
          </div>
        </div>
        <h1 class="plc-h1">价值</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">1、随时随地，远程监控养殖，打开手机或电脑，随时查看舍内实时的环境指标。</p>
          <p class="plc-p">2、可靠报警，守护猪只安全，断电、环境异常时都可以立即远程报警，持续保证牲畜的良好居住环境，降低疾病风险。</p>
          <p class="plc-p">3、批量监督，集中管理，所有的环境大数据记录在服务器中，通过各种报表分析对所有的养殖场进行对比、发现问题、快速找到问题，为后续不断优化养殖提供决策依据。</p>
        </div>
      </div>
      <div class="content" v-else>
        <h1 class="plc-h1">单体栏精喂仪</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">单体栏精确饲喂，降低饲养料成本。</p>
          <p class="plc-p">膘体管控，有利于提高母猪生产力。</p>
          <p class="plc-p">智能饲喂降低人员干预，减轻人员劳动强度。</p>
        </div>
        <h1 class="plc-h1">哺乳母猪饲喂器</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">产后母猪曲线精心饲喂，饲料成本低。</p>
          <p class="plc-p">24小时随时饲喂。</p>
          <p class="plc-p">采食数据适时掌控，母猪个性化管理。</p>
        </div>
        <h1 class="plc-h1">哺乳母猪饲喂器互动版</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">互动式采食，产后母猪想吃就吃。</p>
          <p class="plc-p">智能化饲喂，有效避免饲料浪费。</p>
          <p class="plc-p">完美湿拌。</p>
          <p class="plc-p">采食数据适时掌控，母猪个性化管理。</p>
        </div>
        <h1 class="plc-h1">保育育肥一体机</h1>
        <div class="plc-p-wrapper">
          <p class="plc-p">猪只应激更少，猪群更健康。</p>
          <p class="plc-p">全程粥料饲喂。</p>
          <p class="plc-p">智能饲喂，一站式解决，更少人员干预，生物安全。</p>
        </div>
      </div>
    </div>
  </plc-page>
</template>

<script type="text/ecmascript-6">
  import PlcPage from '../components/page/Index'
  import { SOLUTIONS, AI_PRODUCT_1, AI_PRODUCT_2, AI_PRODUCT_3 } from '../util/constants'
  import { deepClone } from '../util/common'

  export default {
    name: 'Solution',
    components: {
      PlcPage
    },
    data () {
      return {
        banner: require('../assets/images/banner/banner-solution.jpg'),
        menu: deepClone(SOLUTIONS),
        AI_PRODUCT_1,
        AI_PRODUCT_2,
        AI_PRODUCT_3,
        currentMenu: 0
      }
    },
    created () {
      console.log(11)
      const menu = Number(localStorage.getItem('menu'))
      if (!isNaN(menu)) {
        this.changeMenu(menu)
        localStorage.removeItem('menu')
      }
    },
    methods: {
      changeMenu(index) {
        this.menu.forEach((item, idx) => {
          item.active = false
          if (idx === index) {
            item.active = true
          }
        })
        this.currentMenu = index
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .page-solution
    .content
      background-color #fff
      padding 55px 70px
      .plc-h1
        color #040000
        font-size 23px
        font-weight 600
        margin-bottom 25px
      .plc-p-wrapper
        color #363333
        margin-bottom 40px
        .plc-p
          text-indent 2em
          font-size 16px
          line-height 2
        .plc-h2-wrapper
          padding 0 30px
        &:last-child
          margin-bottom 0
      .plc-h2
        color #77b539
        font-size 15px
        margin-bottom 8px
      .plc-p2-wrapper
        margin-bottom 25px
        .plc-p2
          color #474141
          font-size 15px
          line-height 2
      .plc-p-with-pic
        display flex
        align-items center
        .pic-label
          text-align center
          margin-top 10px
          color #363333
        .pic-wrapper
          border 2px solid #eaeaea
          padding 20px
        .pic-mr
          margin-right 40px
          &:last-child
            margin-right 0
        .pic
          width 510px
          height auto
        .pic2-1
          width 470px
        .pic2-2
          width 510px
          height 315px
      .pic-1-4-wrapper
        padding 0 55px
        .width-300
          width 300px
        .width-625
          width 625px
        .height-160
          height 160px
        .height-250
          height 250px
        .margin-r-25
          margin-right 25px
        .margin-b-25
          margin-bottom 25px
      .plc-2-desc
        width 510px
        .desc-item-wrapper
          margin-bottom 20px
          .desc-item
            display flex
            padding 7px 0
            border-bottom 1px #b0b0b0 solid
            font-size 14px
            color #444444
            &.title
              align-items center
              font-size 16px
              color #169dcf
            .desc-key
              width 150px
              min-width 150px
            .desc-value
              line-height 1.2
              flex 1
      .img-func
        width 1000px
        margin 0 30px 30px
</style>
